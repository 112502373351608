<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <div
        class="erp-primary__btn"
        @click="getOutPut"
        v-if="(detailsData.status === 1 || detailsData.status === 4) && hasPermission('erp:output:saveOutputAudit')"
      >
        申请开票
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">查看发票</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号">
              <template #default>
                <div class="erp-view__value record__link" @click="linkTo">
                  {{detailsData.businessContractNo || '-'}}
                </div>
              </template>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="detailsData.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="签约主体" :value="detailsData.contractPrincipalName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="应开票金额" :value="detailsData.arAmountManualDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="未开票金额" :value="detailsData.notInvoicedAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="不开票金额" :value="detailsData.nonInvoicedAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="已开货物发票金额" :value="detailsData.goodsInvoicedAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="已开服务发票金额" :value="detailsData.serviceInvoicedAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="应来票金额" :value="detailsData.apAmountManualDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="未来票金额" :value="detailsData.notApAmountManualDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="已来货物发票金额" :value="detailsData.goodsInvoicedInputAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="已来服务发票金额" :value="detailsData.serviceInvoicedInputAmountDesc"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 开票待审核 -->
    <div v-if="detailsData.auditList && detailsData.auditList.length > 0 && (detailsData.status == 1 || detailsData.status == 4)">
      <!--订单产品信息展示-->
      <div style="margin-top: 30px" class="order-title__bar op-16">
        <div class="erp-form__title f16">开票（待审核）</div>
      </div>
      <el-table :data="detailsData.auditList" border class="egrid erp-table__list erp-view__table" :span-method="columnMerge">
        <el-table-column label="发票品类" prop="invoiceVarietyDesc" align="center" show-overflow-tooltip width="220">
          <template #default="scope">
            <div>{{ filterName(scope.row.invoiceVarietyDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="开票类型" prop="invoiceTypeDesc" align="center" show-overflow-tooltip width="220">
          <template #default="scope">
            <div>{{ filterName(scope.row.invoiceTypeDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="本次开票金额" prop="totalAmountDesc" align="center" show-overflow-tooltip width="240">
          <template #default="scope">
            <div>{{ filterName(scope.row.totalAmountDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="税额" align="center" show-overflow-tooltip width="140">
          <template #default="scope">
            <div>{{ filterName(scope.row.taxAmountDesc) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="开票内容" prop="varietyName" align="center" show-overflow-tooltip width="177">
          <template #default="scope">
            <span class="record__link" @click="showDetail(scope.row)">查看</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip width="250">
          <template #default="scope">
            <div>{{ filterName(scope.row.createdTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建人" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.creator) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="contractAmountDisplay" align="center" width="190">
          <template #default="scope">
            <div class="cell-record">
              <span class="record__link" @click="editInfo(scope.row)" v-if="hasPermission('erp:output:saveOutputAudit')">编辑</span>
              <span class="record__link" @click="cancelOrder(scope.row)" v-if="hasPermission('erp:output:saveOutputAudit')"> 取消</span>
              <span class="record__link" @click="getAudit(scope.row)" v-if="hasPermission('erp:output:auditOutput')">审核</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 开票（审核已通过） -->
    <div v-if="detailsData.passList && detailsData.passList.length > 0">
      <div style="margin-top: 30px" class="order-title__bar op-16">
        <div class="erp-form__title f16">开票（审核已通过）</div>
      </div>
      <div class="claim_company">
        <el-table :data="detailsData.passList" border class="egrid erp-table__list erp-view__table">
          <el-table-column label="发票品类" prop="invoiceVarietyDesc" align="center" show-overflow-tooltip width="130">
            <template #default="scope">
              <div>{{ filterName(scope.row.invoiceVarietyDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="开票类型" prop="invoiceTypeDesc" align="center" show-overflow-tooltip width="130">
            <template #default="scope">
              <div>{{ filterName(scope.row.invoiceTypeDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="本次开票金额" prop="totalAmountDesc" align="center" show-overflow-tooltip width="140">
            <template #default="scope">
              <div>{{ filterName(scope.row.totalAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="税额" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.taxAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="开票内容" prop="varietyName" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <span class="record__link" @click="showDetail(scope.row)">查看</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="expectAmount" align="center" show-overflow-tooltip width="180">
            <template #default="scope">
              <div>{{ filterName(scope.row.createdTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="actualAmount" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>{{ filterName(scope.row.creator) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="审核时间" prop="contractAmountDisplay" align="center" show-overflow-tooltip width="180">
            <template #default="scope">
              <div>{{ filterName(scope.row.auditTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="审核人" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>{{ filterName(scope.row.auditOperator) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="开票日期" align="center" show-overflow-tooltip width="140">
            <template #default="scope">
              <div>{{ filterName(scope.row.invoiceCreatedTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="发票号" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.invoiceNumber) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="contractAmountDisplay" align="center" width="150" v-if="detailsData.status == 1 || detailsData.status == 4">
            <template #default="scope">
              <div class="cell-record">
                <span
                  class="record__link"
                  v-if="(scope.row.invoiceType === 1 || scope.row.invoiceType === 2 || scope.row.invoiceType === 4) && hasPermission('erp:output:auditOutput')"
                  @click="getRecord(scope.row)"
                  >记录开票</span
                >
                <span class="record__link" @click="delAudit(scope.row)" v-if="hasPermission('erp:output:deleteOutput')">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 来票 -->
    <div v-if="detailsData.inputList && detailsData.inputList.length > 0">
      <div style="margin-top: 30px" class="order-title__bar op-16">
        <div class="erp-form__title f16">来票</div>
      </div>
      <div class="claim_company">
        <el-table :data="detailsData.inputList" border class="egrid erp-table__list erp-view__table">
          <el-table-column label="开票类型" prop="plantNo" align="center" show-overflow-tooltip width="250">
            <template #default="scope">
              <div>{{ filterName(scope.row.invoiceTypeDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="本次来票金额" prop="nameCn" align="center" show-overflow-tooltip width="250">
            <template #default="scope">
              <div>{{ filterName(scope.row.totalAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="税额" align="center" show-overflow-tooltip width="180">
            <template #default="scope">
              <div>{{ filterName(scope.row.totalTaxAmountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="varietyName" align="center" show-overflow-tooltip width="220">
            <template #default="scope">
              <div>{{ filterName(scope.row.createdTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="expectAmount" align="center" show-overflow-tooltip width="300">
            <template #default="scope">
              <div>{{ filterName(scope.row.creator) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="开票日期" prop="invoiceCreatedTime" align="center" show-overflow-tooltip width="220">
            <template #default="scope">
              <div>{{ filterName(scope.row.invoiceCreatedTime) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="发票号" prop="invoiceNumber" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.invoiceNumber) }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 关联收款订单 -->
    <erp-drawer :visible="visible" @close="closeDialog" erp :width="960" class="no-padding fix-top-header">
      <saveAuditOutPut v-if="type === 1" :type="auditType" :outputAuditId="outputAuditId" :baseData="detailsData" @cancel="closeDialog"></saveAuditOutPut>
      <recordInvoice v-else :outputAuditId="outputAuditId" :baseData="recordData" @cancel="closeDialog"></recordInvoice>
    </erp-drawer>
  </div>
</template>

<script>
import saveAuditOutPut from './components/saveAuditOutPut.vue';
import recordInvoice from './components/recordInvoice.vue';
import { getRouterParams, handleTableData } from '@/utils/publicMethods';
import { loadPage } from '@/utils/publicMethods';
export default {
  name: 'invoiceDetail',
  components: {
    saveAuditOutPut,
    recordInvoice,
  },
  props: {},
  data() {
    return {
      detailsData: {
        apAmountManual: 0,
        apAmountManualDesc: null,
        arAmountManual: 0,
        arAmountManualDesc: null,
        auditList: [],
        businessContractNo: null,
        customerName: null,
        goodsInvoicedAmount: 0,
        goodsInvoicedAmountDesc: null,
        goodsInvoicedInputAmount: 0,
        goodsInvoicedInputAmountDesc: null,
        goodsInvoicedInputTaxAmount: 0,
        goodsInvoicedInputTaxAmountDesc: null,
        goodsInvoicedTaxAmount: 0,
        goodsInvoicedTaxAmountDesc: null,
        inputList: [],
        nonInvoicedAmount: 0,
        nonInvoicedAmountDesc: null,
        notApAmountManual: 0,
        notApAmountManualDesc: null,
        notInvoicedAmount: 0,
        notInvoicedAmountDesc: null,
        orderId: 0,
        passList: [],
        serviceInvoicedAmount: 0,
        serviceInvoicedAmountDesc: null,
        serviceInvoicedInputAmount: 0,
        serviceInvoicedInputAmountDesc: null,
        serviceInvoicedInputTaxAmount: 0,
        serviceInvoicedInputTaxAmountDesc: null,
        serviceInvoicedTaxAmount: 0,
        serviceInvoicedTaxAmountDesc: null,
        status: null,
        statusDesc: null,
        version: 0,
      },
      orderId: null,
      version: null,
      visible: false, // 侧栏弹出显示
      auditType: 1,
      type: 1, // 1 审核、申请、查看开票 2记录开票
      outputAuditId: null,
      recordData: null,
    };
  },
  computed: {},
  methods: {
    linkTo(){
      loadPage('OrderDetail', {
        orderId: this.orderId,
        version: this.version,
        name: 'Invoice',
      });
    },
    // 为合并行处理表格数据   arr表示源数组， attr 需要判断的属性
    filterTableData(arr, attr) {
      let spanOneArr = [];
      let concatOne = 0;
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          if (item[attr] === arr[index - 1][attr]) {
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return spanOneArr;
    },
    // 行合并
    columnMerge({ rowIndex, columnIndex }) {
      let data = handleTableData(this.detailsData.auditList, 'id', 7, rowIndex, columnIndex);
      return data;
    },
    closeDialog(status) {
      this.visible = false;
      if (status) {
        this.getCollectionData(this.orderId);
      }
    },
    getRecord(row) {
      this.type = 2;
      this.outputAuditId = row.id;
      this.recordData = row;
      this.visible = true;
    },
    editInfo(row) {
      this.type = 1;
      this.outputAuditId = row.id;
      this.auditType = 4; // 1新增 2查看 3审核 4修改
      this.visible = true;
    },
    showDetail(row) {
      this.type = 1;
      this.outputAuditId = row.id;
      this.auditType = 2;
      this.visible = true;
    },
    getOutPut() {
      this.type = 1;
      this.auditType = 1;
      this.visible = true;
    },
    getAudit(row) {
      // 显示审核页面
      this.type = 1;
      this.outputAuditId = row.id;
      this.auditType = 3;
      this.visible = true;
    },
    getCollectionData(orderId) {
      // 获取列表信息
      this.ajax({
        type: 'GET',
        url: `/malicrm/invoice/output/queryInvoiceOutputDetailById/${orderId}`,
        success: (res) => {
          if (res.code === 200) {
            this.detailsData = res.result;
            this.version = res.result.version;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    finishOrder() {
      this.$erpConfirm('确定要完结当前订单吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/order/finishOrder',
            data: {
              orderId: this.detailsData.orderId,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('已完结订单');
                this.getCollectionData(this.orderId);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    reOpenOrder() {
      this.$erpConfirm('确定要恢复当前订单状态为进行中吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/order/reOpenOrder',
            data: {
              orderId: this.detailsData.orderId,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('已恢复订单');
                this.getCollectionData(this.orderId);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    cancelOrder(row) {
      // 取消审核
      this.$erpConfirm('确定取消当前的开票申请吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/invoice/output/cancelOutputAudit',
            data: {
              id: row.id,
              version: row.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.getCollectionData(this.orderId);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    delAudit(row) {
      this.$erpConfirm('确定删除吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/invoice/output/deleteOutput',
            data: {
              id: row.id,
              version: row.version,
              varietyId: row.varietyId,
            },
            success: (res) => {
              if (res.code === 200) {
                this.getCollectionData(this.orderId);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      // 返回
      this.$router.push('/erp/financial/invoice');
    },
  },
  created() {
    const { id, version } = getRouterParams('invoiceDetail');
    this.orderId = id;
    this.version = version;
    this.getCollectionData(id);
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
</style>
