<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">记录开票</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="save">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="开票方" prop="principalFullName">
                <el-input :maxlength="8" v-model="formData.principalFullName" clearable placeholder="请输入开票方" class="mali-full__inputcom" disabled>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票时间" prop="invoiceCreatedTime">
                <el-date-picker style="width: 100%" prefix-icon="null" v-model="formData.invoiceCreatedTime" type="date" placeholder="请选择开票时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票号" prop="invoiceNumber">
                <el-input
                  :maxlength="8"
                  v-model="formData.invoiceNumber"
                  clearable
                  placeholder="请输入发票号"
                  class="mali-full__inputcom"
                  @blur="changeNumber($event, 'invoiceNumber')"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="税额" prop="taxAmount">
                <el-input
                  :maxlength="12"
                  v-model="formData.taxAmount"
                  clearable
                  placeholder="请输入税额"
                  class="mali-full__inputcom"
                  @focus="formatAmount"
                  @blur="changeAmount($event, 'taxAmount')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    type: { type: [String, Number] },
    baseData: { type: [Object] }, // 基础信息
    outputAuditId: { type: [String, Number] },
    version: { type: [String, Number] },
  },
  data() {
    return {
      rules: {
        // 检验规则
        invoiceCreatedTime: [
          {
            required: true,
            message: '请选择开票时间',
            trigger: 'change',
          },
        ],
        invoiceNumber: [
          {
            required: true,
            message: '请输入发票号',
            trigger: 'blur',
          },
          {
            len: 8,
            message: '固定8个字符',
            trigger: 'blur',
          },
        ],
        taxAmount: [
          {
            required: true,
            message: '请输入税额',
            trigger: 'blur',
          },
        ],
      },
      formData: {
        principalFullName: null,
        invoiceCreatedTime: null,
        invoiceNumber: null,
        id: 0,
        taxAmount: 0,
        version: null,
      },
    };
  },
  created() {
    this.formData.principalFullName = this.baseData.principalFullName;
    this.formData.id = this.outputAuditId;
    this.formData.taxAmount = this.baseData.taxAmount;
    this.formData.version = this.baseData.version;
    this.formData.varietyId = this.baseData.varietyId;
    this.formData.invoiceCreatedTime = this.baseData.invoiceCreatedTime;
    this.formData.invoiceNumber = this.baseData.invoiceNumber;
  },
  methods: {
    changeNumber(e, name) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
      this.formData[name] = value || null;
    },
    formatAmount() {
      if (this.formData.taxAmount && this.formData.taxAmount !== '') {
        const value = this.formData.taxAmount.replace(/,/g, '');
        this.formData.taxAmount = value;
      }
    },
    changeAmount(e, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 100000000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 8);
        }
      }
      this.formData[name] = value || null;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.formData);
          // 兼容处理时间格式问题
          params.invoiceCreatedTime = formatTime(params.invoiceCreatedTime, 'YYYY-MM-DD');
          this.ajax({
            type: 'POST',
            url: '/malicrm/invoice/output/setOutputInfo',
            data: params,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('保存成功');
                this.$emit('cancel', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        } else {
          return false;
        }
      });
    },
    back() {
      this.$emit('cancel', false);
    },
  },
};
</script>

<style></style>
